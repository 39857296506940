exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-calendar-js": () => import("./../../../src/pages/calendar.js" /* webpackChunkName: "component---src-pages-calendar-js" */),
  "component---src-pages-community-js": () => import("./../../../src/pages/community.js" /* webpackChunkName: "component---src-pages-community-js" */),
  "component---src-pages-community-wp-workshop-slug-js": () => import("./../../../src/pages/community/{wpWorkshop.slug}.js" /* webpackChunkName: "component---src-pages-community-wp-workshop-slug-js" */),
  "component---src-pages-completed-donation-js": () => import("./../../../src/pages/completed-donation.js" /* webpackChunkName: "component---src-pages-completed-donation-js" */),
  "component---src-pages-events-js": () => import("./../../../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-exhibitions-js": () => import("./../../../src/pages/exhibitions.js" /* webpackChunkName: "component---src-pages-exhibitions-js" */),
  "component---src-pages-exhibitions-wp-gallery-show-slug-js": () => import("./../../../src/pages/exhibitions/{wpGalleryShow.slug}.js" /* webpackChunkName: "component---src-pages-exhibitions-wp-gallery-show-slug-js" */),
  "component---src-pages-gallery-js": () => import("./../../../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mc-thank-you-js": () => import("./../../../src/pages/mc-thank-you.js" /* webpackChunkName: "component---src-pages-mc-thank-you-js" */),
  "component---src-pages-publications-js": () => import("./../../../src/pages/publications.js" /* webpackChunkName: "component---src-pages-publications-js" */),
  "component---src-pages-take-part-js": () => import("./../../../src/pages/take-part.js" /* webpackChunkName: "component---src-pages-take-part-js" */)
}

